.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--unit_7) 0 var(--unit_8);
}

.form {
  padding-bottom: var(--unit_8);
}

.header {
  padding: var(--unit_7) 0 var(--unit_5);
}

.title {
  padding-top: var(--unit_6);
  text-align: center;
}

.subtitle {
  padding-top: var(--unit_2);
}

.description {
  padding: var(--unit_2) 0 var(--unit_6);
}

.form-checkbox {
  padding: var(--unit_4) 0 var(--unit_7);
}

.error {
  padding-top: var(--unit_1);
}
