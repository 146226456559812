.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Фикс проблемы, когда активная кнопка фильтра рендерится поверх самописного попапа "История цены" */
  position: relative;
  z-index: 0;
}

.button-blur {
  filter: blur(10px);
  pointer-events: none;
}
