.container {
  font-size: var(--unit_3);
  font-weight: normal;
  line-height: var(--unit_4);
  padding-top: var(--unit_4);
  color: var(--solid-gray200);
}

.container a {
  text-decoration: none;
  color: var(--solid-blue600);
}
