.price {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.amount {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
}

.history {
  margin-right: 0;
}

.labels {
  margin-top: 2px;
}

.discount {
  flex-basis: 100%;
  margin-bottom: var(--unit_1);

  &.inline {
    margin-bottom: 0;
    flex-basis: auto;
  }
}

.hide-if-empty:empty {
  display: none;
}

.purchase-terms {
  margin-top: var(--unit_1);
}
