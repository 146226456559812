.loader {
  width: 200px;
  height: 44px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.loader::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(290deg, transparent, rgba(255, 255, 255, 0.6), transparent);
  position: absolute;
  top: 0;
  left: 0;
  animation: skeleton-animation 1.2s linear infinite;
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
