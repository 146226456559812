.container {
  display: block;
}

.code {
  margin-bottom: 12px;
  display: block;
  width: 166px;
  height: 166px;
}

.phone {
  display: flex;
  flex-direction: column;
  gap: var(--unit_1);
}

.preview {
  display: block;
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.static-preview {
  margin-left: var(--unit_4);
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url('./assets/qr_logo.svg');
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
}
