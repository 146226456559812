.phone {
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: var(--text-primary-default);
}

.phone:first-child {
  margin-top: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.phone-size-XS {
  font-size: 16px;
  line-height: 1.38;
}

/* stylelint-disable-next-line selector-class-pattern */
.phone-size-M {
  font-size: 28px;
  line-height: 1.21;
}
